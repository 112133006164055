.artwork {
    height: $height-artwork;
    width: $height-artwork;
    border: $border-artwork;
    border-radius: $border-radius-circle;
    overflow: hidden;
    transition: border 300ms;
    // box-shadow: $shadow-library-sidebar;

    img {
        height: 100%;
        border-radius: $border-radius-circle;
    }
}

@media only screen and (min-width: 900px) {
    .artwork {
        height: $height-artwork * 1.25;
        width: $height-artwork * 1.25;
    }
}
