.app {
    &__wrapper {
        display: grid;
        height: 100vh;
        grid-template-columns: 1fr 1fr 1fr;
        grid-template-rows: 1fr 1fr 1fr;
        gap: clamp(1rem, 5vw, 3rem);
        transition: background 300ms, color 300ms, backdrop-filter 100ms;
        transition-timing-function: cubic-bezier(0.45, 0.01, 0.69, 1);

        .nav__header {
            grid-row: 1/2;
        }

        .artwork {
            grid-row: 2/3;
        }

        .song-info {
            grid-row: 3/4;
        }

        .player {
            grid-row: 4/5;
        }

        .artwork,
        .song-info,
        .player {
            place-self: center;
        }
    }
}

.dark-mode {
    background: linear-gradient(135deg, #313131cb 0%, #000000cb 100%);
    color: $color-white;
}
.light-mode {
    background: linear-gradient(135deg, #e6e9f0c5 0%, #eef1f5c5 100%);
    color: $color-darkmode;
}

// Grid Layout for the desktop
@media only screen and (min-width: 900px) {
    body {
        overflow-y: scroll;
        scrollbar-width: thin;
        scrollbar-color: #7777 $color-grey-transparent;
        &::-webkit-scrollbar {
            width: 1rem;
        }
        &::-webkit-scrollbar-track {
            background: $color-grey-transparent;
            border-radius: 10rem;
        }

        &::-webkit-scrollbar-thumb {
            background: #7777;
            border-radius: 10rem;
        }
    }
    .app {
        &__wrapper {
           
            .nav__header {
                grid-column: 2/3;
            }

            .library {
                grid-column: 1/2;
                grid-row: 2/5;
                transform: translateX(0);
            }

            .player,
            .artwork,
            .song-info {
                grid-column: 2/3;
            }
        }
    }
}
