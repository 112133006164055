.about {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    // background-color: #0007;
    backdrop-filter: blur(5rem);
    transition: transform 500ms;
    transition-timing-function: cubic-bezier(0.45, 0, 0.53, 0.97);
    overflow-y: scroll;
    scrollbar-width: thin;
    scrollbar-color: #7777 $color-grey-transparent;
    &::-webkit-scrollbar {
        width: 1rem;
    }
    &::-webkit-scrollbar-track {
        background: $color-grey-transparent;
        border-radius: 10rem;
    }

    &::-webkit-scrollbar-thumb {
        background: #7777;
        border-radius: 10rem;
    }

    &__wrapper {
        max-width: 80rem;
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        gap: 4rem;
        padding: 2rem;
    }

    &--hidden {
        transform: translatey(-100%);
    }
}
