.button {
    text-decoration: none;
    color: $color-white;
    font-weight: 600;
    padding: 2rem 3rem;
    width: max-content;
    align-self: center;
    border-radius: 1.5rem;
    margin-bottom: 2rem;
}
