.about {
    &-socials {
        display: flex;
        flex-direction: column;
        gap: 2rem;

        &-group {
            display: flex;
            gap: 2rem;
            align-items: center;
        }
        &-icon {
            font-size: 1.8rem;
        }
        &-text {
            font-size: 1.5rem;
        }
    }
}
